<template>
    <div class="container">
        <div class="col-wrap header">
            <div class="bg bg-animation2"></div>
            <div class="wrap-content">
                <Nav></Nav>
                <div class="content">
                    <h1>SUCCESSFUL CASE</h1>
                    <h3>用最契合的传播方式，让每一个案例都成为经典</h3>
                    <p>
                        We are committed to make classic cases with our tailored communication solutions
                    </p>
                </div>
            </div>
        </div>
        <div class="col-wrap case">
            <div class="wrap-content">
                <div class="content">
                    <ul class="tab">
                        <li :class="tabIdx == 1 ? 'active' : ''" @click="tabHandle(1)">
                            <div>
                                汽车行业<br />
                                <i class="triangle-up"></i>
                            </div>
                        </li>
                        <li :class="tabIdx == 2 ? 'active' : ''" @click="tabHandle(2)">
                            <div>非汽车行业 <br /><i class="triangle-up"></i></div>
                        </li>
                    </ul>
                    <ul class="list" v-if="tabIdx === 1">
                        <template v-if="dataList[0] != null">
                            <li v-for="item in dataList[0].list" :key="item.id">
                                <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,h_357,w_420'" />
                                <a :href="'/case/detail?id=' + item.id">
                                    <div class="mask">
                                        <h1>{{ item.title }}</h1>
                                        <p v-html="item.desc"></p></div
                                ></a></li
                        ></template>
                    </ul>
                    <ul class="list" v-if="tabIdx === 2">
                        <template v-if="dataList[1] != null">
                            <li v-for="item in dataList[1].list" :key="item.id">
                                <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,h_357,w_420'" />
                                <a :href="'/case/detail?id=' + item.id">
                                    <div class="mask">
                                        <h1>{{ item.title }}</h1>
                                        <p v-html="item.desc"></p></div
                                ></a></li
                        ></template>
                    </ul>
                    <div class="show-more" v-if="dataList[tabIdx - 1] && !dataList[tabIdx - 1].ended">
                        <a class="blue-btn" :class="loading ? 'disabled' : ''" @click="loadData"><i v-if="loading" class="iconfont icon-loading"></i>查看更多案例</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-wrap">
            <div class="content">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import settings from "@/settings";
export default {
    name: "pc-case",
    components: { Nav, Footer },
    data() {
        return {
            loading: false,
            resourceBaseUrl: settings.resourceBaseUrl,
            tabIdx: 1,
            dataList: [],
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        tabHandle(e) {
            this.tabIdx = e;
            var data = this.dataList[this.tabIdx - 1];
            if (!data || (!data.ended && data.list.length == 0)) {
                this.loadData();
            }
        },
        loadData() {
            var data = this.dataList[this.tabIdx - 1];
            if (!data) {
                data = {
                    page: 0,
                    ended: false,
                    list: [],
                };
                this.dataList[this.tabIdx - 1] = data;
            }
            this.loading = true;
            this.$http
                .get(`/api/case/list/${this.tabIdx}?page=${data.page + 1}`)
                .then((res) => {
                    data.page++;
                    data.list = data.list.concat(res.data);
                    data.ended = res.data.length < 9;
                    this.$forceUpdate();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/pc.css");
@import url("../../../common/css/icon.css");

.show-more {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .list li {
        width: 32.5%;
        margin: 6px 0;
    }
}

.list li img {
    width: 100%;
}
.list li .mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 61, 147, 0);
    opacity: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(0);
}

.list li:hover .mask {
    background: rgba(0, 61, 147, 0.9);
    opacity: 1;
    transform: scale(1);
}

.case .tab {
    border-bottom: 1px #f0f0f0 solid;
    margin: 70px 10%;
    display: flex;
}
.case .tab li {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -3px;
    cursor: pointer;
    text-align: center;
}
.case .tab li div {
    width: 200px;
    font-size: 20px;
    font-weight: normal;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-bottom: 5px transparent solid;
}
.case .tab li div i {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    margin-top: 10px;
}

.case .tab .active div {
    border-bottom: 5px #0048b1 solid;
    color: #0048b1;
}
.case .tab .active div i {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #0048b1;
}

.triangle-up {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #0048b1;
}

.header .wrap-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header .wrap-content .content {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1440px) {
    .header .bg {
        background: url("~@/assets/pc/case/banner-bg.jpg") center bottom;
        height: 500px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
        text-align: center;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
        text-align: center;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .list {
        display: grid;
        grid-template-columns: repeat(3, 324px);
        grid-row-gap: 14px;
        grid-column-gap: 14px;
        margin-bottom: 50px;
        display: flex\9;
        flex-wrap: wrap\9;
        justify-content: space-between\9;
    }
    .list li {
        height: 277px;
        position: relative;
        cursor: pointer;
    }
    .list li .mask h1 {
        font-size: 16px;
        margin: 15px 15px;
        width: 90%;
    }
    .list li .mask p {
        font-size: 14px;
        line-height: 30px;
        margin: 15px 15px;
        word-wrap: break-word;
        width: 90%;
    }
    .case .wrap-content .content {
        width: 1000px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1441px) {
    .header .bg {
        background: url("~@/assets/pc/case/banner-bg.jpg") center bottom;
        height: 760px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
        text-align: center;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
        text-align: center;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 14px;
        grid-column-gap: 14px;
        margin-bottom: 50px;
        display: flex\9;
        flex-wrap: wrap\9;
        justify-content: space-between\9;
    }
    .list li {
        position: relative;
        cursor: pointer;
    }
    .list li .mask h1 {
        font-size: 16px;
        margin: 15px 15px;
        width: 90%;
    }
    .list li .mask p {
        font-size: 16px;
        line-height: 30px;
        margin: 15px 15px;
        word-wrap: break-word;
        width: 90%;
    }
    .case .wrap-content .content {
        width: 1280px;
        margin: 0 auto;
    }
}
</style>
